import { stringBase64, STORAGE_PREFIX } from "./constants"

let prefix = STORAGE_PREFIX + "/";

export default {
    setItem: function (name, value) {
        localStorage.setItem(stringBase64(`${prefix}${name}`), stringBase64(value))
    },
    setItems: function (object) {
        for (const key in object) { this.setItem(`${key}`, object[key]) }
    },
    getItem: function (name) { return stringBase64(localStorage.getItem(stringBase64(`${prefix}${name}`)), true) },
    getItems: function (names_array) {
        return names_array.reduce((prev, current) => {
            prev[current] = this.getItem(`${current}`);
            return prev;
        }, {})
    },
    deleteItem: function (name) { localStorage.removeItem(stringBase64(`${prefix}${name}`)) },
    deleteItems: function (names_array) {
        names_array.forEach(e => { this.deleteItem(`${e}`) })
    },
    clear: function () { localStorage.clear() }
}

export const sessionStorageService = {
    setItem: function (name, value) {
        sessionStorage.setItem(stringBase64(`${prefix}${name}`), stringBase64(value))
    },
    setItems: function (object) {
        for (const key in object) { this.setItem(`${key}`, object[key]) }
    },
    getItem: function (name) { return stringBase64(sessionStorage.getItem(stringBase64(`${prefix}${name}`)), true) },
    getItems: function (names_array) {
        return names_array.reduce((prev, current) => {
            prev[current] = this.getItem(`${current}`);
            return prev;
        }, {})
    },
    deleteItem: function (name) { sessionStorage.removeItem(stringBase64(`${prefix}${name}`)) },
    deleteItems: function (names_array) {
        names_array.forEach(e => { this.deleteItem(`${e}`) })
    },
    clear: function () { sessionStorage.clear() }
}